import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "gatsby-image"
import LoginModal from "../components/sales-pages/loginModal"
import Hero from "../components/sales-pages/hero"
import VSL from "../components/sales-pages/vsl"
import Bio from "../components/mentorBio"
import FAQ from "../components/faq"
import CTAButton from "../components/sales-pages/ctaButton"
import LectureList from "../components/lectureList"
import LiveChat from "../components/sales-pages/liveChat"
import { useStaticQuery, graphql } from "gatsby"
import { useAuth } from "gatsby-theme-firebase"
import "../styles/education.scss"
import "../styles/content.scss"
import publicLectureInfo from "../lectures/ammp"

export default ({ pageContext: { course } }) => {
  const { title, subtitle, slug } = course
  const [toggleLogin, setToggleLogin] = React.useState(false)

  const { isLoggedIn } = useAuth()
  const images = useStaticQuery(graphql`
    query {
      hero: file(absolutePath: { regex: "/education/ammp/ammp-hero.jpg/" }) {
        childImageSharp {
          fixed {
            src
          }
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo: file(absolutePath: { regex: "/education/ammp.jpg/" }) {
        childImageSharp {
          fixed {
            src
          }
        }
      }
      results: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
          dir: { regex: "/education/ammp/results/" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const resultsImages = images.results.edges
  return (
    <Layout>
      <SEO
        title={`${title}: ${subtitle}`}
        description={subtitle}
        image={images.logo.childImageSharp.fixed.src}
      />

      {toggleLogin && (
        <LoginModal
          isLoggedIn={isLoggedIn}
          course={course}
          slug={slug}
          setToggleLogin={setToggleLogin}
        />
      )}
      <Hero
        image={images.hero.childImageSharp.fluid}
        title={title}
        subtitle={subtitle}
        button={
          <CTAButton
            isLoggedIn={isLoggedIn}
            slug={slug}
            setToggleLogin={setToggleLogin}
          />
        }
      />

      <div className="course-sales-content">
        <div className="row">
          <div className="column50">
            <VSL vidLink="417751366" />
          </div>
          <div className="column50">
            <p className="video-explainer">
              Did you know there are <b className="red-underline">FOUR</b>{" "}
              methods to sell on Amazon? Most people only know of one or maybe
              two. In this course, you will learn ALL four. and from there
              you'll be able to decide which is the best fit for you!
              <CTAButton
                isLoggedIn={isLoggedIn}
                slug={slug}
                setToggleLogin={setToggleLogin}
              />
            </p>
          </div>
        </div>
        <div className="row">
          <p>
            This course will cover absolutely EVERYTHING you need to know to get
            from{" "}
            <b>
              $0 to $10,000/mo <i>at LEAST</i>
            </b>
            . You do not need any experience in eCommerce or Amazon to take this
            course.
          </p>
          <p>
            If you DO know how to sell on Amazon, there is still a ton of
            content that will be relevant for you. A lot of tips and tricks that
            I have picked up as well as tips from other professional sellers! If
            you only know Private Label, come learn Online Arbitrage. Want to
            learn how to Wholesale on Amazon? This course is for you!
          </p>

          <p>
            Things change all the time on Amazon which is why this course will
            be updated frequently to include any changes to policies or rules.
            I'll always be adding new tips that I find out over time.
          </p>
          <p>
            The course includes all 4 ways to sell on Amazon. Every way to sell
            is different and may appeal to different people. In this course,
            <b> you'll learn them all:</b>
          </p>

          <p>
            <b>
              <u>Retail Arbitrage:</u>
            </b>
          </p>
          <br />
          <br />
          <ul className="fancy-checks">
            <li>How to find profitable items from retail locations.</li>
            <li>Which locations are best for arbitrage?</li>
            <li>What tools you will need.</li>
            <li>
              Staying competitive and winning the Buy Box to increase sales.
            </li>
          </ul>
          <p>
            <b>
              <u>Online Arbitrage:</u>
            </b>
          </p>
          <ul className="fancy-checks">
            <li>Finding the best deals online using deal sites.</li>
            <li>
              Getting extra money back using cashback rewards and websites.
            </li>
            <li>Increase profit margins by using bundled products.</li>
            <li>How to use product lists to get ahead of your competition.</li>
            <li>How to automate the entire process of arbitrage.</li>
            <li>How to use Tactical Arbitrage and 3rd party prep companies.</li>
            <li>
              <b className="red-underline">
                How to get UNGATED in restricted categories and Brands (Like
                Disney, Hasbro, Star Wars, ect.)
              </b>
            </li>
          </ul>
          <p>
            <b>
              <u>Private Label:</u>
            </b>
          </p>
          <ul className="fancy-checks">
            <li>How to find products using methods no one else teaches.</li>
            <li>The criteria that make a good product to sell.</li>
            <li>How to calculate all the costs to ensure high profits.</li>
            <li>
              How to contact and negotiate with manufacturers.
              <b className="red-underline">&nbsp;[FREE Template]</b>
            </li>
            <li>
              How to import goods the easiest way using a 3rd party forwarder.
            </li>
            <li>Patent lookup and legal guidelines for private label.</li>
            <li>How to get reviews while still following Amazon's policies.</li>
            <li>
              How to create a product that stands out from your competition.
            </li>
            <li>
              How to make a listing that&nbsp;
              <u>no one else can compete with.</u>
            </li>
            <li>
              Utilizing Pay Per Click (PPC) Advertising to get to the front
              page.
            </li>
            <li>Registering a trademark for your brand name.</li>
            <li>
              Using&nbsp;<em>Amazon Brand Registry</em>&nbsp;to unlock&nbsp;
              <b>hidden features</b>&nbsp;only big brands use!
            </li>
            <li>
              How to&nbsp;<b>Launch&nbsp;</b>your new product using the most
              cutting edge methods (rarely seen elsewhere)
            </li>
            <li>
              How to create promotions and coupons to increase interest in your
              new product.
            </li>
          </ul>
          <p>
            <b>
              <u>Wholesale:</u>
            </b>
          </p>
          <ul className="fancy-checks">
            <li>
              <b className="red-underline">
                FREE 450+ company wholesaler list included!
              </b>
            </li>
            <li>
              Building a relationship with suppliers so they will offer lower
              prices.
            </li>
            <li>Attending events and trade shows to build relationships.</li>
            <li>
              Using programs to sort through catalogs for profit products.
            </li>{" "}
          </ul>
          <p>
            <b>
              <u>Scaling:</u>
            </b>
          </p>
          <ul className="fancy-checks">
            <li>
              Outsourcing the<em>&nbsp;entire process</em>&nbsp;to a small team
              of employees.
            </li>
            <li>
              How to go about&nbsp;<b>hiring&nbsp;</b>the best employees that
              will get the job done!
            </li>
            <li>
              How to&nbsp;<b>train&nbsp;</b>your employees (hint: use this
              program!)
            </li>
            <li>
              How to&nbsp;<b>acquire&nbsp;</b>OTHER people's businesses to
              really pick up your sales.
            </li>
          </ul>
          <p>
            <b>
              <u>
                <em>Bonus Extras:</em>
              </u>
            </b>
          </p>
          <ul className="fancy-checks">
            <li>Access to all past live streams and LIVE product sourcing.</li>
            <li>Getting any negative feedback REMOVED from your account.</li>
            <li>Exit strategies (how to sell your business for millions).</li>
            <li>
              Setting up your Tax Free certificate so you don't pay sales tax!
            </li>
          </ul>
        </div>
        <hr />
        <div className="row">
          <p>What my students have said about the Mentorship Program:</p>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {resultsImages.map((resultImage, i) => {
              return (
                <Image
                  key={i}
                  fluid={resultImage.node.childImageSharp.fluid}
                  style={{ width: 200 }}
                  imgStyle={{ height: "auto" }}
                />
              )
            })}
          </div>
          <p>
            <strong>
              and SO SO many more. To see even more student results, see the
              'Student Fame' story on&nbsp;
              <a
                href="https://www.instagram.com/jordan_kilburn/"
                target="_blank"
              >
                my Instagram here
              </a>
              .
            </strong>
          </p>
        </div>
        <div style={{ maxWidth: "400px", margin: "4rem auto" }}>
          <CTAButton
            isLoggedIn={isLoggedIn}
            slug={slug}
            setToggleLogin={setToggleLogin}
          />
        </div>
        <br />
        <br />
        <hr />
        <div className="row">
          <Bio />
        </div>
        <br />
        <hr />
        <br />
        <h2>📚&nbsp;Course Curriculum</h2>
        <LectureList
          lectureInfo={publicLectureInfo}
          chooseLecture={null}
          chosenLecture={{}}
        />{" "}
        <hr />
        <br />
        <FAQ />
        <br />
        <hr />
        <div style={{ maxWidth: "400px", margin: "4rem auto" }}>
          <CTAButton
            isLoggedIn={isLoggedIn}
            slug={slug}
            setToggleLogin={setToggleLogin}
          />
        </div>
      </div>
      <LiveChat />
    </Layout>
  )
}
